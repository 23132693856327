import React, { ReactElement } from 'react';
import parse from 'react-html-parser';

interface Props {
  textData?: {
    textBlock: string;
  };
  blogData?: string;
  brandDescription?: string;
  
}

const GeneralTextBlock = (props: Props) : ReactElement => {
  
  if (props.blogData || props.brandDescription) {
    return (
      <div>
        <br />
        <div className="textBlock">{parse(props.blogData || props.brandDescription)}</div>
      </div> 
    );     
  } else {    
    const { textBlock } = props.textData;
    return(
    <div>
      <br />
      <div className="textBlock">{parse(textBlock)}</div>
    </div>
    );
  }
};

export default GeneralTextBlock;
