import  parse  from 'html-react-parser'
import React from 'react'

import './TwoParagraphSection.scss'

interface Props {
    data: {
        RightParagraphHeader: string;
        RightParagraphText: string; 
        LeftParagraphHeader: string;
        LeftParagraphText: string;
        ButtonText: string;
    }
}

const TwoParagraphSection = (props: Props) => {

    const { RightParagraphHeader, RightParagraphText, LeftParagraphHeader, LeftParagraphText, ButtonText } = props.data

    return (
    <section className="tyreInfoSection">
      <div className="tyreInfoSectionWrapper">
        <div className="tyreInfoSectionText">
          <h3 className="tyreInfoSectionTextH3">
            {LeftParagraphHeader}
          </h3>
          {parse(LeftParagraphText)}
        </div>
        <div className="tyreInfoSectionText">
          <h3 className="tyreInfoSectionTextH3">
            {RightParagraphHeader}
          </h3>
          {parse(RightParagraphText)}
        </div>
      </div>
      {/* <button type="button" className="btnRed">
       {ButtonText}
      </button> */}
    </section>    
    )
}

export default TwoParagraphSection
