import Layout from '../../components/Layout/Layout';
import GuidesHeroSec from '@motokiki/shared/guidesHeroSec';
import BrandSec from '@motokiki/shared/brandSec';
import GeneralTextBlock from '../../components/GeneralTextBlock';
import TwoParagraphSec from '../../components/TwoParagraphSection';
import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import './TyreTypesPage.scss';

interface Props {
  data: {
    strapi: {
      typesOfTyrePage: {
        Content: {
          map: (component) => any;
          __typename: string;
          GeneralTextBlock: {
            id: string;
            textBlock: string
          }
          GeneralSplitParagraphsWithButton: {
            id: string;
            ButtonLink: string;
            ButtonText: string;
            ExternalLink: string;
            LeftParagraphHeader: string;
            LeftParagraphText: string;
            RightParagraphHeader: string;
            RightParagraphText: string;
          }
          GeneralBrandsSection: {
            buttonText: string;
            headerText: string;
            image1?: {
              url: string;
              alternativeText: string;
            }
            image2?: {
              url: string;
              alternativeText: string;
            }
            image3?: {
              url: string;
              alternativeText: string;
            }
            image4?: {
              url: string;
              alternativeText: string;
            }
            image5?: {
              url: string;
              alternativeText: string;
            }
            image6?: {
              url: string;
              alternativeText: string;
            }
            image7?: {
              url: string;
              alternativeText: string;
            }
            image8?: {
              url: string;
              alternativeText: string;
            }
            subText?: string;
          }
          GeneralTypesOfTyreBanner: {
            id: string;
            buttonText: string;
            subParagraph: string;
            typeOfTyre: string;
            mainImg: {
              url: string;
            }
          }
        }
        HeroSection: {
          coverIcon: {
            url: string;
          }
          coverImg: {
            url: string;
          }
          headerText: string;
          iconActive: string;
        }
        Seo: {
          description: string;
          title: string;
        }
      }
    }
  }
}

const TyreTypesPage = ({ data }: Props): ReactElement => {

  const { typesOfTyrePage } = data.strapi;

  return (
    <>
      <Layout>
        <GuidesHeroSec
          data={typesOfTyrePage.HeroSection}
        />

        {typesOfTyrePage.Content.map((component, i) => {
          return (
            <>
              {component.__typename === 'strapi_ComponentGeneralTextBlock' && (
                <section className="tyreTypeSec">
                  <div className="tyreTypeSecWrapper">
                    <GeneralTextBlock key={i} textData={component} />
                  </div>
                </section>
              )}

              {component.__typename === 'strapi_ComponentGeneralSplitParagraphsWithButton' && (
                <TwoParagraphSec data={component}
                />
              )}

              {component.__typename === 'strapi_ComponentGeneralBrandsSection' && (
                <BrandSec data={component}
                />
              )}

              {/* OFR Mike asked for this to be removed! but left the info in the graphQL query*/}
              {/* {component.__typename === 'strapi_ComponentGeneralTypesOfTyreBanner' && (
                <TyreTypeBanner data={component}
                />
              )} */}
            </>
          );
        })}
        <SearchWidgetContainer />
      </Layout>
    </>
  );
}

export const typesOfTyres = graphql`
query ($id: ID!) {
  strapi {
    typesOfTyrePage(id: $id, publicationState: PREVIEW) {
      Content {
        __typename
        ... on strapi_ComponentGeneralTextBlock {
          id
          textBlock
        }
        ... on strapi_ComponentGeneralSplitParagraphsWithButton {
          id
          ButtonLink
          ButtonText
          ExternalLink
          LeftParagraphHeader
          LeftParagraphText
          RightParagraphHeader
          RightParagraphText
        }
        ... on strapi_ComponentGeneralBrandsSection {
          buttonText
          headerText
          image1 {
            url
            alternativeText
          }
          image2 {
            url
            alternativeText
          }
          image3 {
            url
            alternativeText
          }
          image4 {
            url
            alternativeText
          }
          image5 {
            url
            alternativeText
          }
          image6 {
            url
            alternativeText
          }
          image7 {
            url
            alternativeText
          }
          image8 {
            url
            alternativeText
          }
          subText
        }
        ... on strapi_ComponentGeneralTypesOfTyreBanner {
          id
          buttonText
          subParagraph
          typeOfTyre
          mainImg {
            url
          }
        }
      }
      HeroSection {
        coverIcon {
          url
        }
        coverImg {
          url
        }
        headerText
        iconActive
      }
      Seo {
        description
        title
      }
    }
  }
}
`

export default TyreTypesPage;
